import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { AuctionStatus } from '../../../constants/AuctionStatus';

import {
  clearPhotosInServiceHistory,
  editAuction,
} from '../../../addvehicle/AddVehicleActions';
import {
  acceptByBuyer,
  acceptBySeller,
  cancelAuction,
  deleteAuction,
  rejectByBuyer,
  rejectBySeller,
  setVehicleFilter,
} from '../../../myvehicles/MyVehiclesActions';
import { auctionListType } from '../../../constants/AuctionListType';
import RouterPaths from '../../../constants/RouterPaths';
import leftArrowIcon from '../../../assets/img/icons/arrow-left.svg';
import CommonSweetAlert from '../CommonSweetAlert';
import { CommonSweetAlertVariants } from '../../../constants/CommonSweetAlertVariants';
import { getShortTitle } from '../../helpers/vehicleHelper';
import { prepareBidsData } from '../../helpers/prepareBidsData';
import { setBidsFilter } from '../../../mybids/MyBidsActions';

const AuctionDetailsHeader = ({
  listType,
  className,
  item,
  loading,
  setIsAuctionRelaunched,
  setIsRepublish,
  nextStep,
  refreshItem,
  location,
}) => {
  const { id: auctionId } = useParams();
  const isOwner = useSelector(state => state.auth.get('isOwner'));
  const isSseRefresh = useSelector(state => state.addVehicle.get('isSseRefresh'));
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const id = useSelector(state => state.auth.get('id'));
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [prevPageFilterStatus, setPrevPageFilterStatus] = useState(null);
  const [isPrevPageSelling, setIsPrevPageSelling] = useState(false);

  const isLoading = loading;
  const history = useHistory();
  const dispatch = useDispatch();

  const { winningBid } = prepareBidsData(item.bids, organizationId);

  const backLink = useMemo(() => {
    switch (listType) {
      case auctionListType.MY_VEHICLES:
        return RouterPaths.MY_VEHICLES;
      case auctionListType.MY_BIDS:
        return RouterPaths.MY_BIDS;
      case auctionListType.SEARCH:
      default:
        return RouterPaths.SEARCH;
    }
  }, [listType]);

  const handleRelaunchAuction = () => {
    setIsAuctionRelaunched(true);
    nextStep(prevStep => prevStep + 1);
    setIsRepublish(true);
  };

  const handleCancelAuction = () => {
    dispatch(
      cancelAuction(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handleRejectAuctionBySeller = () => {
    dispatch(
      rejectBySeller(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handleAcceptAuctionBySeller = () => {
    dispatch(
      acceptBySeller(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handleRejectAuctionByBuyer = () => {
    dispatch(
      rejectByBuyer(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handleAcceptAuctionByBuyer = () => {
    dispatch(
      acceptByBuyer(item, data => {
        refreshItem(data);
      }),
    );
  };

  const handlePublishAuction = () => {
    nextStep(prevStep => prevStep + 1);
    setIsRepublish(false);
  };

  const renderButtons = () => {
    const isAuctionSeller = item.seller.id === id;
    const isWonBidder = winningBid?.bidderId === id;

    switch (item.status) {
      case AuctionStatus.CANCELLED:
      case AuctionStatus.ENDED:
        return (
          <>
            {item?.seller?.id === id && (
              <CommonButton
                label="Delete"
                variant={CommonButtonVariants.DELETE}
                handleClick={() => setConfirmDelete(true)}
              />
            )}
            {item.isRepublishAllowed && (
              <CommonButton
                className="btn-primary"
                label="Edit"
                variant={
                  isLoading || !isSseRefresh
                    ? CommonButtonVariants.DISABLED
                    : CommonButtonVariants.PRIMARY
                }
                disabled={isLoading || !isSseRefresh}
                handleClick={() =>
                  dispatch(
                    editAuction({
                      auctionId,
                      version: item?.version,
                      status: item?.status,
                    }),
                  )
                }
              />
            )}
            {item.isRepublishAllowed && (
              <CommonButton
                label="Relaunch"
                variant={
                  isLoading || !isSseRefresh
                    ? CommonButtonVariants.DISABLED
                    : CommonButtonVariants.SUCCESS
                }
                handleClick={handleRelaunchAuction}
                disabled={isLoading || !isSseRefresh}
              />
            )}
          </>
        );
      case AuctionStatus.PUBLISHED:
        return (
          <>
            {item?.isCancellationAllowed && (
              <>
                <CommonButton
                  className="mt-md-0"
                  label="Cancel"
                  variant={CommonButtonVariants.PRIMARY}
                  handleClick={() => setConfirmCancel(true)}
                />
              </>
            )}
          </>
        );
      case AuctionStatus.TO_ACCEPT:
      case AuctionStatus.WAITING:
        if (isWonBidder) {
          return (
            <>
              <CommonButton
                label={
                  item?.isBuyerAcceptanceAllowed
                    ? 'Waiting for you to accept'
                    : "Waiting for the seller's acceptance"
                }
                variant={CommonButtonVariants.AUCTION}
                color={
                  item?.isBuyerAcceptanceAllowed
                    ? AuctionStatus.TO_ACCEPT
                    : AuctionStatus.WAITING
                }
              />
              <CommonButton
                className="reject-button"
                label="Reject"
                color={AuctionStatus.CANCELLED}
                handleClick={handleRejectAuctionByBuyer}
                disabled={!item?.isBuyerAcceptanceAllowed}
              />
              <CommonButton
                label="Accept"
                variant={CommonButtonVariants.PRIMARY}
                handleClick={handleAcceptAuctionByBuyer}
                disabled={!item?.isBuyerAcceptanceAllowed}
              />
            </>
          );
        }
        if (isAuctionSeller) {
          return (
            <>
              <CommonButton
                label={
                  item?.isSellerAcceptanceAllowed
                    ? 'Waiting for you to accept'
                    : "Waiting for the buyer's acceptance"
                }
                variant={CommonButtonVariants.AUCTION}
                color={
                  item?.isSellerAcceptanceAllowed
                    ? AuctionStatus.TO_ACCEPT
                    : AuctionStatus.WAITING
                }
              />
              <CommonButton
                className="reject-button"
                label="Reject"
                color={AuctionStatus.CANCELLED}
                handleClick={handleRejectAuctionBySeller}
                disabled={!item?.isSellerAcceptanceAllowed}
              />
              <CommonButton
                label="Accept"
                variant={CommonButtonVariants.PRIMARY}
                handleClick={handleAcceptAuctionBySeller}
                disabled={!item?.isSellerAcceptanceAllowed}
              />
            </>
          );
        }
        return <></>;
      case AuctionStatus.SOLD: {
        return <></>;
      }
      case AuctionStatus.REJECTED: {
        return (
          <>
            {item.isRepublishAllowed === true && (
              <>
                <CommonButton
                  label="Edit"
                  variant={CommonButtonVariants.PRIMARY}
                  handleClick={() =>
                    dispatch(
                      editAuction({
                        auctionId,
                        version: item?.version,
                        status: item?.status,
                      }),
                    )
                  }
                />
                <CommonButton
                  label="Relaunch"
                  variant={CommonButtonVariants.SUCCESS}
                  handleClick={handleRelaunchAuction}
                />
              </>
            )}
          </>
        );
      }
      case AuctionStatus.DRAFT: {
        const isLaunchEnabled = (isOwner || item?.seller?.id === id) && item?.isDraftValid;
        return (
          <>
            {(isOwner || item?.seller?.id === id) && (
              <>
                <CommonButton
                  label="Delete"
                  variant={CommonButtonVariants.DELETE}
                  handleClick={() => setConfirmDelete(true)}
                />
              </>
            )}
            {(isOwner || item?.seller?.id === id) && (
              <CommonButton
                className="btn-primary mt-0"
                label="Edit"
                variant={CommonButtonVariants.PRIMARY}
                handleClick={() => {
                  dispatch(
                    editAuction({
                      auctionId,
                      version: item?.version,
                      status: item?.status,
                    }),
                  );
                  dispatch(clearPhotosInServiceHistory());
                }}
              />
            )}
            {isLaunchEnabled && (
              <CommonButton
                className="mt-0"
                label="Launch"
                variant={CommonButtonVariants.SUCCESS}
                handleClick={handlePublishAuction}
              />
            )}
          </>
        );
      }
      default:
        return '';
    }
  };

  useEffect(() => {
    if (location?.state?.filter) {
      setIsPrevPageSelling(location?.pathname.includes(RouterPaths.MY_VEHICLES));
      setPrevPageFilterStatus(location?.state?.filter);
      location.state.filter = null;
    }
  }, [location]);

  return (
    <Row className={`${className} auction-detail-header account-layout__header`}>
      <Col md={12} className="p-0 d-flex flex-row">
        <CommonButton
          label="Back"
          variant={CommonButtonVariants.PRIMARY}
          handleClick={() => {
            if (isPrevPageSelling) {
              dispatch(setVehicleFilter(prevPageFilterStatus));
            } else {
              dispatch(setBidsFilter(prevPageFilterStatus));
            }
            history.push(backLink, {
              ...(location.state || {}),
              auctionId: item.id,
            });
          }}
          icon={leftArrowIcon}
          iconAlt="Back"
          iconLeft={true}
        />
        <div className="ml-auto d-flex flex-row auction-detail-header__actions-container">
          {renderButtons()}
        </div>
        <CommonSweetAlert
          variant={CommonSweetAlertVariants.INFO}
          show={confirmCancel}
          onConfirm={() => {
            handleCancelAuction();
            setConfirmCancel(false);
          }}
          onCancel={() => setConfirmCancel(false)}
        >
          <p className="common-sweet-alert__title">
            Please confirm that you want to cancel auction?
          </p>
        </CommonSweetAlert>
        <CommonSweetAlert
          variant={CommonSweetAlertVariants.ERROR}
          show={confirmDelete}
          onConfirm={() => {
            if (isPrevPageSelling) {
              dispatch(setVehicleFilter(prevPageFilterStatus));
            } else {
              dispatch(setBidsFilter(prevPageFilterStatus));
            }
            dispatch(
              deleteAuction({
                id: auctionId,
                version: item?.version,
              }),
            );
            setConfirmDelete(false);
          }}
          onCancel={() => setConfirmDelete(false)}
        >
          <p className="common-sweet-alert__title">
            Please confirm that you want to delete the below vehicle?
          </p>
          <p className="common-sweet-alert__text">{getShortTitle(item)}</p>
        </CommonSweetAlert>
      </Col>
    </Row>
  );
};

AuctionDetailsHeader.defaultProps = {
  className: '',
  loading: false,
  location: undefined,
  setIsRepublish: () => {},
  nextStep: () => {},
};

AuctionDetailsHeader.propTypes = {
  listType: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  nextStep: PropTypes.func,
  setIsRepublish: PropTypes.func,
  refreshItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  location: PropTypes.object,
};

export default AuctionDetailsHeader;
