import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AddDescriptionForm from './AddDescriptionForm';
import validationSchema from './AddDescriptionForm.schema';
import {
  getDraft,
  getOrganisationDescription,
  saveDescriptionAndSettingsDraft,
  saveDescriptionToOrganisation,
} from '../AddVehicleActions';

const AddDescriptionPage = ({ prevForm, generalValues, nextForm, updateParentForm, form }) => {
  const [initialValues, setInitialValues] = useState({
    description: form?.values?.descriptionAndSettings?.description,
    defaultDescription: form?.values?.descriptionAndSettings?.defaultDescription,
  });

  const formRef = useRef();
  const dispatch = useDispatch();

  const id = useSelector(state => state.addVehicle.get('id'));
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );

  const loadInitialValues = async () => {
    if (
      !descriptionAndSettings?.defaultDescription &&
      descriptionAndSettings?.defaultDescription !== ''
    ) {
      getOrganisationDescription().then(res => {
        setInitialValues({
          description: descriptionAndSettings?.description,
          defaultDescription: res?.data?.data || '',
        });
      });
    } else {
      setInitialValues({
        description: descriptionAndSettings?.description,
        defaultDescription: descriptionAndSettings
          ? descriptionAndSettings?.defaultDescription
          : '',
      });
    }
  };

  useEffect(() => {
    loadInitialValues();
  }, []);

  // const initialValues = () => {
  //   description: descriptionAndSettings ? descriptionAndSettings?.description : '',
  //   defaultDescription: descriptionAndSettings ? descriptionAndSettings?.defaultDescription : ''
  // };

  const handleSubmit = async () => {
    if (formRef?.current) {
      const { description, defaultDescription } = formRef?.current?.values;

      const data = {
        ...descriptionAndSettings,
        description,
        defaultDescription,
      };
      dispatch(
        saveDescriptionAndSettingsDraft(data, () => {
          saveDescriptionToOrganisation(defaultDescription || '');
          dispatch(getDraft(id));
          nextForm();
        }),
      );
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {form => (
        <AddDescriptionForm
          form={form}
          prevForm={prevForm}
          generalValues={generalValues}
          handleSubmit={handleSubmit}
          nextForm={nextForm}
          updateParentForm={updateParentForm}
        />
      )}
    </Formik>
  );
};

AddDescriptionPage.propTypes = {
  prevForm: PropTypes.func.isRequired,
  generalValues: PropTypes.object.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddDescriptionPage;
