import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPhotoDescription } from '../../common/helpers/getPhotoDescription';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import CommonButton from '../../common/components/CommonButton';
import AddPhotoDrag from '../drag/AddPhotoDrag';
import {
  clearDamagesWithNoDamageType,
  getDraft,
  saveAddPhotoDraft,
} from '../AddVehicleActions';
import AddDamageDrag from '../drag/AddDamageDrag';
import AddDamageDragPreview from '../drag/AddDamageDragPreview';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import AddVehicleHeader from '../AddVehicleHeader';
import photosListIcon from '../../assets/img/photos-list-icon.png';
import { AddVehicleStepsMapping } from '../../constants/AddVehicle';
import AddVehicleFooter from '../AddVehicleFooter';
import { ADD_DRAFT_VALIDATION_ERRORS } from '../AddVehicleReducer';
import { validateVehiclePhotos } from '../helpers/draftValidator';

const AddPhotoPage = ({ values, prevForm, nextForm, form }) => {
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const dispatch = useDispatch();
  const valuesRef = useRef();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const photos = useSelector(state => state.addVehicle.get('photos'));
  const uploadPhotoError = useSelector(state => state.addVehicle.get('uploadPhotoError'));
  const index = currentStep - 1;
  // const uploadedPhotos = useMemo(() => {
  //   return photos?.filter(el => el?.fileUrl) || [];
  // }, [photos]);

  useEffect(() => {
    form.setFieldValue('vehicleMedia.photos', photos);
  }, [photos]);

  const damages =
    values?.vehicleMedia?.photos && values?.vehicleMedia?.photos[index]?.damages
      ? values?.vehicleMedia?.photos[index]?.damages
      : [];
  const isPhotoLoaded = values.vehicleMedia?.photos[index]?.fileUrl;

  // const handleNextButton = () => {
  //   dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id))));
  //   return nextForm(AddVehicleStepsMapping.VIDEOS);
  // };

  const _nextForm = () => {
    dispatch({
      type: ADD_DRAFT_VALIDATION_ERRORS,
      payload: {
        formStep: 4,
        errors: validateVehiclePhotos(valuesRef.current),
      },
    });
    nextForm(5);
  };

  const handleSave = () => {
    dispatch(clearDamagesWithNoDamageType(photos));
    dispatch(
      saveAddPhotoDraft(draftId => {
        dispatch(getDraft(id || draftId));
      }),
    );
  };

  const handleBack = () => {
    dispatch(clearDamagesWithNoDamageType(photos));
    if (photos?.length) {
      nextForm(5);
    } else {
      prevForm();
    }
  };

  const handleGoToSummary = () => {
    dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id)))).then(() =>
      nextForm(AddVehicleStepsMapping.PHOTOS_SUMMARY),
    );
  };

  useEffect(
    () => () => {
      dispatch({
        type: ADD_DRAFT_VALIDATION_ERRORS,
        payload: {
          formStep: 4,
          errors: validateVehiclePhotos(valuesRef.current),
        },
      });
    },
    [],
  );

  useEffect(() => {
    valuesRef.current = values;
  }, [values, photos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  // const isDisabled = !(
  //   photos[index]?.data ||
  //   (photos[index]?.fileUrl && (!damages || !damages.some(dmg => !dmg.damageType)))
  // );

  const isDisabled =
    photos[index]?.data ||
    (photos[index]?.fileUrl && damages && damages.some(dmg => !dmg.damageType));

  const doneIsDisabled = !(
    !values?.vehicleMedia?.photos[index]?.fileUrl ||
    (photos[index]?.fileUrl && (!damages || !damages.some(dmg => !dmg.damageType)))
  );

  return (
    <>
      <AddVehicleHeader saveDraftButtonHandler={handleSave} />
      <AddVehicleContentWrapper>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <h5 className="font-weight-bold m-0">Photos</h5>
            <p className="text-sm mt-2">A Minimum of 12 images is required to proceed.</p>
          </Col>
          <Col sm={12} md={6}>
            <CommonButton
              className="basic-data__button add-photo__summary-button ml-auto w-100"
              label="PHOTOS SUMMARY"
              variant={CommonButtonVariants.OUTLINED}
              disabled={isLoading || doneIsDisabled || !photos?.length}
              handleClick={handleGoToSummary}
              icon={photosListIcon}
              iconLeft={true}
              iconAlt="Photos Summary"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm={12}>
            <Row>
              <Col>
                <p>{getPhotoDescription(currentStep).DESCRIPTION}</p>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <AddPhotoDrag step={currentStep} values={values} nextForm={_nextForm} />
                {uploadPhotoError && (
                  <label className="error-label">Upload only image/video format.</label>
                )}
              </Col>
            </Row>
            {currentStep > 1 && isPhotoLoaded && (
              <Row className="mb-3">
                <Col>
                  <h5 className="font-weight-bold">Damage</h5>
                  <p className="text-sm">Upload pictures of vehicle damage.</p>
                </Col>
              </Row>
            )}
            <Row>
              {damages.length > 0 && <AddDamageDragPreview values={values} form={form} />}
              {currentStep > 1 && isPhotoLoaded && damages.length < 5 && (
                <Col md={4} sm={12}>
                  <AddDamageDrag form={form} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          handleSave();
          nextForm();
        }}
        backButtonHandler={handleBack}
        nextButtonDisabled={!!isLoading || isDisabled}
      />
    </>
  );
};

AddPhotoPage.propTypes = {
  values: PropTypes.object.isRequired,
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddPhotoPage;
