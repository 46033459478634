import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import { isEmpty } from 'lodash';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonSize, CommonButtonVariants } from '../../constants/CommonButtonVariants';
import {
  fetchAllVehicleFeatures,
  getDraft,
  saveVehicleFeaturesDraft,
} from '../AddVehicleActions';
import FormPill from '../../common/components/formFields/FormPill';
import { FeatureGroups } from '../../constants/FeatureGroups';
import FormInputField from '../../common/components/formFields/FormInputField';
import { KEY_CODES } from '../../constants/KeyCodes';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import AddVehicleFooter from '../AddVehicleFooter';
import {
  ADD_DRAFT_VALIDATION_ERRORS,
  VALIDATE_VEHICLE_FEATURES_SUCCESS,
} from '../AddVehicleReducer';
import { validateVehicleFeatures } from '../helpers/draftValidator';

const { CAR_FEATURE, CAR_FEATURE_SELECTED } = CommonButtonVariants;
const { CAP_FEATURES_GROUP } = FeatureGroups;

const AddFeaturesForm = ({ prevForm, nextForm, values, form }) => {
  const [ref, setRef] = useState(null);
  const dispatch = useDispatch();
  const valuesRef = useRef();
  const vehicleFeaturesCustomRef = useRef();
  const vehicleFeaturesDictionaryRef = useRef();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const allVehicleFeatures = useSelector(state => state.addVehicle.get('allVehicleFeatures'));
  const id = useSelector(state => state.addVehicle.get('id'));
  const vehicleFeaturesDictionaryIds = useSelector(state =>
    state.addVehicle.get('vehicleFeatures'),
  )?.vehicleFeaturesDictionaryIds;
  const vehicleFeaturesDictionaryState = useSelector(state =>
    state.addVehicle.get('vehicleFeaturesDictionary'),
  );
  const vehicleFeatures = useSelector(state => state.addVehicle.get('vehicleFeatures'));

  const [vehicleFeaturesDictionary, setVehicleFeaturesDictionary] = useState([]);
  const [vehicleFeaturesCustom, setVehicleFeaturesCustom] = useState(
    values?.vehicleFeatures?.vehicleFeaturesCustom.map(item => {
      return {
        value: item,
        selected: true,
      };
    }),
  );

  useEffect(() => {
    vehicleFeaturesDictionaryRef.current = vehicleFeaturesDictionary;
  }, [vehicleFeaturesDictionary]);

  const isAddingCustomFeatureDisabled =
    !form?.values?.newCustomFeature.trim() ||
    vehicleFeaturesCustom.findIndex(
      item => item.value.toLowerCase() === form.values.newCustomFeature.toLowerCase(),
    ) !== -1;

  useEffect(
    () => () => {
      if (vehicleFeaturesCustomRef?.current?.vehicleFeaturesCustom) {
        form.setFieldValue(
          'vehicleFeatures.vehicleFeaturesCustom',
          vehicleFeaturesCustomRef.current.vehicleFeaturesCustom
            .filter(it => it.selected)
            .map(it => it.value),
        );
      }
      form.setFieldValue('capFeatures', valuesRef.current.capFeatures);
      const dictionariesIds = valuesRef.current.vehicleFeatures.vehicleFeaturesDictionary.map(
        it => {
          if (typeof it === 'object') {
            return it?.id;
          }
          return it;
        },
      );
      dispatch({
        type: VALIDATE_VEHICLE_FEATURES_SUCCESS,
        payload: {
          vehicleFeatures: {
            ...vehicleFeatures,
            vehicleFeaturesDictionaryIds: dictionariesIds,
          },
          vehicleFeaturesDictionary: vehicleFeaturesDictionaryState,
        },
      });
      handleSaveDraft();
    },
    [],
  );

  useEffect(() => {
    valuesRef.current = values;
    valuesRef.current.vehicleFeatures.vehicleFeaturesCustom = vehicleFeaturesCustom.filter(
      it => it.selected,
    );
  }, [values, vehicleFeaturesCustom, vehicleFeaturesDictionary]);

  useEffect(() => {
    if (vehicleFeaturesDictionaryIds) {
      setVehicleFeaturesDictionary(vehicleFeaturesDictionaryIds);
    }
  }, [vehicleFeaturesDictionaryIds]);

  useEffect(() => {
    vehicleFeaturesCustomRef.current = {
      vehicleFeaturesCustom,
    };
  }, [vehicleFeaturesCustom]);

  useEffect(() => {
    dispatch(fetchAllVehicleFeatures());
  }, [dispatch]);

  const { capFeatures } = values;

  const isFeatureOn = featureName => {
    return Object.keys(capFeatures).some(key => capFeatures[key] === featureName);
  };

  const handleAddCapFeature = item => {
    setVehicleFeaturesDictionary(prevState => {
      if (vehicleFeaturesDictionary.some(el => el.id === item.id)) {
        return prevState.filter(prevItem => prevItem.id !== item.id);
      }
      if (vehicleFeaturesDictionary.some(el => el === item.id)) {
        return prevState.filter(prevItem => prevItem !== item.id);
      }

      const featuresArray = [...prevState, item];
      form.setFieldValue('vehicleFeatures.vehicleFeaturesDictionary', featuresArray);
      return featuresArray;
    });
  };

  const handleSaveDraft = () => {
    dispatch({
      type: ADD_DRAFT_VALIDATION_ERRORS,
      payload: {
        formStep: 9,
        errors: validateVehicleFeatures(valuesRef.current),
      },
    });
    let preparedVehicleCapFeatures;
    let preparedVehicleFeaturesCustom;

    if (vehicleFeaturesDictionaryRef.current?.length) {
      preparedVehicleCapFeatures = vehicleFeaturesDictionaryRef.current.map(item => {
        if (typeof item === 'object') {
          return item.id;
        }
        return item;
      });
    } else {
      preparedVehicleCapFeatures = null;
    }

    if (vehicleFeaturesCustomRef.current?.length) {
      preparedVehicleFeaturesCustom = vehicleFeaturesCustomRef.current
        .filter(item => item.selected)
        .map(item => {
          return item.value;
        });
    } else {
      preparedVehicleFeaturesCustom = null;
    }

    const vehicleFeatures = {
      vehicleFeaturesDictionaryIds: preparedVehicleCapFeatures,
      vehicleFeaturesCustom: preparedVehicleFeaturesCustom,
    };

    dispatch(
      saveVehicleFeaturesDraft(vehicleFeatures, draftId => {
        dispatch(getDraft(id || draftId));
      }),
    );
  };

  const handleCustomFeatureState = value => {
    setVehicleFeaturesCustom(prevState =>
      prevState.map(item => {
        if (item.value === value) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      }),
    );
  };

  const handleCustomFeatureAdd = () => {
    setVehicleFeaturesCustom(prevState => [
      ...prevState,
      { value: form.values.newCustomFeature, selected: true },
    ]);
    form.setFieldValue('newCustomFeature', '');
    ref.focus();
  };

  const handleEnterKeyAdd = e => {
    if (!isAddingCustomFeatureDisabled && e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault();
      handleCustomFeatureAdd();
    }
  };

  return (
    <>
      <AddVehicleHeader
        saveDraftButtonHandler={handleSaveDraft}
        saveDraftButtonDisabled={isLoading || !allVehicleFeatures?.length}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Specification</h5>
            <p className="text-sm mt-2">
              Please add the relevant vehicle specification and any additional spec.
            </p>
          </Col>
        </Row>
        <Form autoComplete="off">
          <Row className="mt-4">
            <Col md={12}>
              {!isLoading && allVehicleFeatures.length > 0 && (
                <>
                  <Row className="mb-4">
                    <Col md={12}>
                      <p className="text-sm font-weight-bold">
                        Click Tags to Select the Vehicle Specification
                      </p>
                    </Col>
                    <Col
                      md={12}
                      style={isEmpty(vehicleFeaturesCustom) ? { paddingBottom: '34px' } : null}
                      className="mt-2"
                    >
                      {allVehicleFeatures &&
                        allVehicleFeatures.map(item => {
                          return (
                            <FormPill
                              key={item.id}
                              feature={item.value}
                              isFeatureSelected={
                                allVehicleFeatures.length ? isFeatureOn : null
                              }
                              groupName={CAP_FEATURES_GROUP}
                              handleClick={() => handleAddCapFeature(item)}
                            />
                          );
                        })}{' '}
                      {vehicleFeaturesCustom &&
                        vehicleFeaturesCustom.map(({ value, selected }) => {
                          return (
                            <div key={value}>
                              <CommonButton
                                variant={selected ? CAR_FEATURE_SELECTED : CAR_FEATURE}
                                label={value}
                                handleClick={() => handleCustomFeatureState(value)}
                              />
                            </div>
                          );
                        })}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <FormInputField
                        name="newCustomFeature"
                        withButton
                        wrapperClassName="form-field__inline-input-wrapper"
                        buttonClassNames="form-field__common-button"
                        buttonLabel="ADD"
                        buttonSize={CommonButtonSize.SMALL}
                        buttonHandleClick={handleCustomFeatureAdd}
                        buttonDisabled={isAddingCustomFeatureDisabled}
                        setRef={setRef}
                        handleKeyDown={e => handleEnterKeyAdd(e)}
                        label="Add Additional Specification"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          nextForm();
        }}
        nextButtonDisabled={!!isLoading}
        backButtonHandler={prevForm}
      />
    </>
  );
};

AddFeaturesForm.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default AddFeaturesForm;
