import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import validationSchema from '../forms/ServiceHistoryForm.schema';
import ServiceHistoryForm from '../forms/ServiceHistoryForm';
import { validateServiceHistory } from '../AddVehicleActions';
import { reverseDateToString } from '../../common/utils/date/Date.utils';
import { ServiceHistory, ServiceHistoryName } from '../../constants/ServiceHistory';

const ServiceHistoryPage = ({ updateParentForm, values, prevForm, nextForm }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [isSchemaActive, setSchemaActive] = useState(true);
  const [data, setData] = useState({
    type:
      !!values?.serviceHistory?.type && typeof values.serviceHistory.type === 'string'
        ? {
            value: values.serviceHistory.type,
            label: ServiceHistoryName[values.serviceHistory.type],
          }
        : values.serviceHistory.type,
    manualRecords: values?.serviceHistory?.manualRecords,
    photoRecords: values?.serviceHistory?.photoRecords,
    photoRecordsToDelete: values?.serviceHistory?.photoRecordsToDelete,
  });

  useEffect(() => {
    setData({
      type:
        !!values?.serviceHistory?.type && typeof values.serviceHistory.type === 'string'
          ? {
              value: values.serviceHistory.type,
              label: ServiceHistoryName[values.serviceHistory.type],
            }
          : values.serviceHistory.type,
      manualRecords: values?.serviceHistory?.manualRecords,
      photoRecords: values?.serviceHistory?.photoRecords,
      photoRecordsToDelete: values?.serviceHistory?.photoRecordsToDelete,
    });
  }, [values]);

  const photoRecordsFromState = Array.from(
    useSelector(state => state.addVehicle.get('photoRecords')),
  );

  const handlePostSubmit = values => {
    let manualRecordsAllowed = true;
    for (let i = 0; i < values?.manualRecords.length; i++) {
      if (
        isEmpty(values?.manualRecords[i].date) ||
        isEmpty(values?.manualRecords[i].mileage.toString()) ||
        isEmpty(values?.manualRecords[i].serviceName)
      ) {
        manualRecordsAllowed = false;
      }
    }
    if (values?.type?.value === ServiceHistory.NONE || !manualRecordsAllowed) {
      values.manualRecords = [];
    }
    for (let i = 0; i < values?.manualRecords?.length; i++) {
      values.manualRecords[i].date = reverseDateToString(values.manualRecords[i].date);
    }
    if (values?.type?.value === ServiceHistory.NONE) {
      values.photoRecordsToDelete = [...values.photoRecords.map(el => el.mediaMetadataId)];
      values.photoRecords = [];
    }

    const data = {
      type: values?.type?.value || null,
      manualRecords: values?.manualRecords || [
        {
          date: '',
          mileage: '',
          serviceName: '',
        },
      ],
      photoRecordsToDelete: values?.photoRecordsToDelete || [],
      photoRecords: values?.photoRecords || [],
    };
    dispatch(validateServiceHistory(data, () => nextForm()));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setData(values);
    handlePostSubmit(values);
    setSubmitting(false);
  };

  const handleValidate = values => {
    setSchemaActive(false);
    let manualRecordsAllowed = true;
    for (let i = 0; i < values?.manualRecords.length; i++) {
      if (
        !isEmpty(values?.manualRecords[i]?.date) ||
        !isEmpty(values?.manualRecords[i]?.mileage?.toString()) ||
        !isEmpty(values?.manualRecords[i]?.serviceName)
      ) {
        manualRecordsAllowed = false;
      }
    }
    if (
      (values?.type?.value === ServiceHistory.FULL ||
        values?.type?.value === ServiceHistory.PARTIAL) &&
      isEmpty(photoRecordsFromState)
    ) {
      setSchemaActive(true);
    }
    if (
      values?.type?.value === ServiceHistory.NONE ||
      (!isEmpty(values?.photoRecords) && manualRecordsAllowed)
    ) {
      setSchemaActive(false);
    }
  };

  useEffect(
    () => () => {
      updateParentForm(formRef.current.values);
    },
    [],
  );

  useEffect(() => {
    handleValidate(data);
  });

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={data}
        validationSchema={isSchemaActive ? validationSchema : null}
        onSubmit={handleSubmit}
        validate={handleValidate}
        validateOnChange={true}
        enableReinitialize
      >
        {formik => {
          return (
            <ServiceHistoryForm
              prevForm={prevForm}
              values={formik?.values}
              form={formik}
              nextForm={nextForm}
            />
          );
        }}
      </Formik>
    </>
  );
};

ServiceHistoryPage.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default ServiceHistoryPage;
