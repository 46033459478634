import { AuctionStatusType } from 'itrade-admin-panel/src/constants/AuctionStatusType';
import { validateFormField } from '../../common/helpers/validateFormField';
import { FormFieldTypes } from '../../constants/FormFieldTypes';
import { Fuel as VehicleFuels } from '../../constants/Vehicle';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import { ServiceHistory } from '../../constants/ServiceHistory';

export const validateVehicleRegistrationPlate = values => {
  const { registrationPlate } = values?.basicData;
  const errors = {
    'Vehicle Registration': validateFormField(registrationPlate, null, true, 10),
  };
  return removeEmptyEntries(errors);
};

export const validateVehicleBasicData = values => {
  const {
    registrationPlate,
    mileage,
    makeSelect,
    modelSelect,
    year,
    derivative,
    carType,
    transmission,
    engineSize,
    fuel,
    colour,
    doors,
    dateOfFirstRegistration,
    previousKeepersInTotal,
    vin,
  } = values?.basicData;

  const isElectricFuel = values?.basicData?.fuel?.value === VehicleFuels.ELECTRIC;
  const errors = {
    'Vehicle Registration': validateFormField(registrationPlate, null, true, 10),
    Millage: validateFormField(mileage, FormFieldTypes.NUMBER, true, 9),
    Make: validateFormField(makeSelect.value, FormFieldTypes.STRING, true),
    Model: validateFormField(modelSelect.value, FormFieldTypes.STRING, true),
    Year: validateFormField(year.value, FormFieldTypes.PROD_YEAR, true, 4),
    Derivative: validateFormField(derivative, FormFieldTypes.DERIVATIVE, true, 150),
    'Body Type': validateFormField(carType, FormFieldTypes.STRING, true, 20),
    Transmission: validateFormField(transmission.value, FormFieldTypes.STRING, true, 40),
    'Engine Size': validateFormField(
      engineSize,
      FormFieldTypes.ENGINE_SIZE,
      !isElectricFuel,
      9,
    ),
    Fuel: validateFormField(fuel.value, FormFieldTypes.STRING, true, 20),
    Colour: !colour?.length ? 'Field is required' : null,
    Doors: validateFormField(doors.value, FormFieldTypes.DOORS, true, 2),
    'Registration Date': validateFormField(
      dateOfFirstRegistration,
      FormFieldTypes.DATE_OF_REG,
      true,
    ),
    'Previous Keepers': validateFormField(
      previousKeepersInTotal,
      FormFieldTypes.PREV_KEEPERS,
      true,
      2,
    ),
    VIN: validateFormField(vin, FormFieldTypes.VIN, true, 17),
  };
  return removeEmptyEntries(errors);
};

export const validateVehicleTyres = values => {
  const errors = {
    'Nearside Front': validateFormField(values?.nearsideFront, FormFieldTypes.STRING, true),
    'Nearside Rear': validateFormField(values?.nearsideRear, FormFieldTypes.STRING, true),
    'Offside Front': validateFormField(values?.offsideFront, FormFieldTypes.STRING, true),
    'Offside Rear': validateFormField(values?.offsideRear, FormFieldTypes.STRING, true),
  };
  return removeEmptyEntries(errors);
};

export const validateVehiclePhotos = values => {
  const enoughPhotos =
    values?.vehicleMedia?.photos?.filter(it => !!it.fileUrl)?.length >= REQUIRED_PHOTOS_AMOUNT;
  return enoughPhotos ? {} : 'Not enough photos';
};

export const validateVehicleDescription = values => {
  const errors = {
    Description: !values?.description?.length ? 'Field is required' : null,
  };
  return removeEmptyEntries(errors);
};

export const validateVehicleFeatures = values => {
  let enoughFeatures;
  if (values?.capFeatures?.length) {
    enoughFeatures =
      !!values?.capFeatures?.filter(it => !!it)?.length ||
      !!values?.vehicleFeatures?.vehicleFeaturesCustom.length;
  } else {
    enoughFeatures =
      !!values?.vehicleFeatures?.vehicleFeaturesDictionary?.length ||
      !!values?.vehicleFeatures?.vehicleFeaturesCustom?.length;
  }
  return enoughFeatures ? {} : 'Not enough features';
};

export const validateServiceHistory = values => {
  if (values?.type) {
    if (
      typeof values?.type === 'object'
        ? values?.type?.value === ServiceHistory.NONE
        : values?.type === ServiceHistory.NONE
    ) {
      return {};
    }
    if (values?.photoRecords?.length) {
      return {};
    }
    if (
      !values?.manualRecords?.filter(it => !!it.date || !!it.mileage || !!it.serviceName)
        ?.length
    ) {
      return 'Manual records or scans should be specified';
    }

    let errors = {};
    values.manualRecords.forEach(it => {
      const dateError = !it?.date || !it?.date?.length ? 'Field is required' : null;
      const mileageError =
        !it?.mileage || !it?.mileage?.toString().length ? 'Field is required' : null;
      const serviceNameError =
        !it?.serviceName || !it?.serviceName?.length ? 'Field is required' : null;
      if (!!dateError?.length && !Object.keys(errors).includes('Date')) {
        errors = {
          ...errors,
          Date: dateError,
        };
      }
      if (!!mileageError?.length && !Object.keys(errors).includes('Mileage')) {
        errors = {
          ...errors,
          Mileage: mileageError,
        };
      }
      if (!!serviceNameError?.length && !Object.keys(errors).includes('Service name')) {
        errors = {
          ...errors,
          'Service name': serviceNameError,
        };
      }
    });
    return errors;
  }
  return 'Service History Type not specified';
};

export const validateLaunchData = values => {
  let errors = {
    Location: !values?.vehicleLocation?.value?.length ? 'Field is required' : null,
    Time: !values?.timeOption?.value?.length ? 'Field is required' : null,
    Status: !values?.type?.value?.length ? 'Field is required' : null,
  };

  if (values?.type?.value === AuctionStatusType.PHYSICAL) {
    errors = {
      ...errors,
      'Reserve Price':
        !values?.autoAcceptPriceInPence || !values?.autoAcceptPriceInPence?.toString().length
          ? 'Field is required'
          : null,
    };
  } else if (values?.type?.value === AuctionStatusType.MINIMUM_PRICE) {
    errors = {
      ...errors,
      'No Reserve Price':
        !values?.minimumPriceInPence || !values?.minimumPriceInPence?.toString().length
          ? 'Field is required'
          : null,
    };
  }
  return removeEmptyEntries(errors);
};

const removeEmptyEntries = obj => {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key] != null) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};
