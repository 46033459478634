import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormInputField, {
  FormInputFieldType,
} from '../../common/components/formFields/FormInputField';
import {
  getDraft,
  saveDescriptionAndSettingsDraft,
  saveDescriptionToOrganisation,
} from '../AddVehicleActions';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import AddVehicleFooter from '../AddVehicleFooter';
import {
  ADD_DRAFT_VALIDATION_ERRORS,
  VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS,
} from '../AddVehicleReducer';
import { validateVehicleDescription } from '../helpers/draftValidator';

const AddDescriptionForm = ({ form, prevForm, nextForm, updateParentForm }) => {
  const dispatch = useDispatch();
  const valuesRef = useRef();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const descriptionAndSettings = useSelector(state =>
    state.addVehicle.get('descriptionAndSettings'),
  );

  const id = useSelector(state => state.addVehicle.get('id'));
  // const [ref, setRef] = useState(null);

  const handleSaveDraft = async () => {
    const { description, defaultDescription } = valuesRef.current;

    const data = {
      ...descriptionAndSettings,
      description,
      defaultDescription,
    };
    await saveDescriptionToOrganisation(defaultDescription || '');
    dispatch({
      type: ADD_DRAFT_VALIDATION_ERRORS,
      payload: {
        formStep: 10,
        errors: validateVehicleDescription(valuesRef.current),
      },
    });
    dispatch(
      saveDescriptionAndSettingsDraft(data, draftId => {
        dispatch(getDraft(id || draftId));
      }),
    );
    updateParentForm(data);
    form.setErrors({});
  };

  useEffect(() => {
    valuesRef.current = form?.values;
    updateParentForm(form?.values);
  }, [form?.values]);

  useEffect(
    () => () => {
      dispatch({
        type: VALIDATE_DESCRIPTION_AND_SETTINGS_SUCCESS,
        payload: {
          descriptionAndSettings: {
            ...descriptionAndSettings,
            description: valuesRef.current?.description,
            defaultDescription: valuesRef.current?.defaultDescription,
          },
        },
      });
      dispatch({
        type: ADD_DRAFT_VALIDATION_ERRORS,
        payload: {
          formStep: 10,
          errors: validateVehicleDescription(valuesRef.current),
        },
      });
      handleSaveDraft();
    },
    [],
  );

  return (
    <>
      <AddVehicleHeader
        saveDraftButtonHandler={handleSaveDraft}
        saveDraftButtonDisabled={isLoading}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">Description</h5>
            <p className="text-sm mt-2">
              Add an accurate detailed description of the vehicle and its condition.
            </p>
          </Col>
        </Row>
        <Form autoComplete="off" className="text-left">
          <Row className="mt-3">
            <Col>
              <FormInputField
                label="Use your own words to describe the vehicle and its condition in more detail"
                type={FormInputFieldType.TEXTAREA}
                id="add-description__user-description"
                name="description"
                setRef={() => {}}
                required
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <FormInputField
                label="Please add a default text that will be added to all your vehicle listings"
                type={FormInputFieldType.TEXTAREA}
                id="add-description__user-default-description"
                name="defaultDescription"
                setRef={() => {}}
              />
            </Col>
          </Row>
        </Form>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          nextForm();
        }}
        backButtonHandler={prevForm}
        nextButtonDisabled={isLoading}
      />
    </>
  );
};

AddDescriptionForm.propTypes = {
  form: PropTypes.object.isRequired,
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default AddDescriptionForm;
