import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CommonButton from './CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';

const cookieKey = 'fee-alert';

const FeeAlertContainer = () => {
  const [isOpen, setIsOpen] = useState(
    (() => {
      return !Cookies.get(cookieKey);
    })(),
  );

  return (
    <div className="fees-modal-wrapper">
      <Modal className="fees-modal" isOpen={isOpen}>
        <ModalHeader tag="h8" className="fees-modal__header">
          📣 Important Update 📣
        </ModalHeader>
        <ModalBody className="fees-modal__body">
          <p className="fees-modal-font">
            <strong className="fees-modal-font">Starting Monday, 10th February 2025,</strong>{' '}
            our buyer’s fees will be updated.
          </p>
          <table className="buyers-fees-table">
            <thead>
              <tr>
                <th>
                  <span className="fees-modal-table-font">Price Range</span>
                </th>
                <th className="fees-modal-table-font">Buyer’s Fee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fees-modal-table-font">£0 - £1,000</td>
                <td className="fees-modal-table-font">£35.00 + VAT</td>
              </tr>
              <tr>
                <td className="fees-modal-table-font">£1,001 - £2,500</td>
                <td className="fees-modal-table-font">£50.00 + VAT</td>
              </tr>
              <tr>
                <td className="fees-modal-table-font">£2,501 - £5,000</td>
                <td className="fees-modal-table-font">£75.00 + VAT</td>
              </tr>
              <tr>
                <td className="fees-modal-table-font">£5,001 - £10,000</td>
                <td className="fees-modal-table-font">£95.00 + VAT</td>
              </tr>
              <tr>
                <td className="fees-modal-table-font">£10,001 - £20,000</td>
                <td className="fees-modal-table-font">£125.00 + VAT</td>
              </tr>
              <tr>
                <td className="fees-modal-table-font">£20,001 +</td>
                <td className="fees-modal-table-font">£150.00 + VAT</td>
              </tr>
            </tbody>
          </table>
          <p className="fees-modal-font m-0 mt-3 mb-2">
            This small adjustment ensures you{' '}
            <strong className="fees-modal-font">
              continue to benefit from the lowest buyer’s fees in the industry.
            </strong>{' '}
            Plus, we remain the{' '}
            <strong className="fees-modal-font">
              only auction offering cars exclusively from franchised main dealers.
            </strong>
          </p>
          <p className="fees-modal-font m-0 mb-1">
            Thank you for your continued trust and support!
          </p>
          <p className="fees-modal-font">
            <strong className="fees-modal-font mb-0">– The Auction4Dealers Team</strong>
          </p>
        </ModalBody>
        <ModalFooter className="fees-modal__footer m-0 p-0">
          <CommonButton
            className="fees-modal-font"
            label="GOT IT"
            handleClick={() => {
              Cookies.set(cookieKey, true);
              setIsOpen(false);
            }}
            variant={CommonButtonVariants.PRIMARY}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FeeAlertContainer;
