import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CommonButton from '../CommonButton';
import { CommonButtonVariants } from '../../../constants/CommonButtonVariants';
import { FormInputField, FormInputFieldType } from '../formFields';
import { sendQuestions } from '../../../customsite/CustomSiteActions';
import phoneIcon from '../../../assets/img/icons/phone.svg';
import mailIcon from '../../../assets/img/icons/mail.svg';

const ContactUsForm = ({ light }) => {
  const dispatch = useDispatch();
  const profileEmail = useSelector(state => state.myProfile.get('myProfileDetails').email);
  const initialValues = {
    type: '',
    name: '',
    email: profileEmail || '',
    contactNumber: '',
    message: '',
  };

  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({
    type: '',
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  });

  const handleContactForm = (values, { setSubmitting }) => {
    dispatch(sendQuestions(values)).finally(setSubmitting(false));
  };

  // eslint-disable-next-line no-unused-vars
  const { type, name, email, message, contactNumber } = errors;

  return (
    <Row className={`contact-us ${light ? 'light' : ''}`}>
      <Col sm={12} md={6} className="contact-us-left">
        <p className="font-weight-bold text-3xl contact-us__title">
          Need more information?
          <br />
          It&apos;s time to get in touch.
        </p>
        <Row className="contact-us-card m-0 mb-5">
          <div className="contact-us-card__icon-box d-flex justify-content-center align-items-center">
            <img src={phoneIcon} alt="contact-us-phone" />
          </div>
          <Col className="ml-4">
            <p className="font-weight-bold text-xl mb-1">Phone</p>
            <p className="text-base">Mon-Fri from 9AM to 5PM</p>
            <p className="font-weight-bold">
              <a href="tel:0(116)482-6044">(0116) 4826044</a>
            </p>
          </Col>
        </Row>
        <Row className="contact-us-card m-0 mb-5">
          <div className="contact-us-card__icon-box d-flex justify-content-center align-items-center">
            <img src={mailIcon} alt="contact-us-mail" />
          </div>
          <Col className="ml-4">
            <p className="font-weight-bold text-xl mb-1">Email</p>
            <p className="text-base">Write to us! Our team is always ready to help</p>
            <p className="font-weight-bold">
              <a href="mailto:contact@auction4dealers.co.uk">contact@auction4dealers.co.uk</a>
            </p>
          </Col>
        </Row>
      </Col>
      <Col
        sm={12}
        md={6}
        className="contact-us-right d-flex justify-content-center align-items-center"
      >
        <Formik initialValues={initialValues} onSubmit={handleContactForm}>
          {({ values, setFieldValue }) => (
            <Form className="contact-us-form w-100">
              <Row>
                <Col>
                  <p className="font-weight-bold label-required">I&apos;m interested in...</p>
                  <Row className="d-flex justify-content-between">
                    <Col sm={12} md={4}>
                      <CommonButton
                        className={`w-100 text-sm font-weight-normal interested-button ${
                          values?.type === 'SELLING' ? 'active' : ''
                        }`}
                        variant={CommonButtonVariants.OUTLINED}
                        label="SELLING"
                        handleClick={() => {
                          setFieldValue('type', 'SELLING');
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <CommonButton
                        className={`w-100 text-sm font-weight-normal interested-button ${
                          values?.type === 'BUYING' ? 'active' : ''
                        }`}
                        variant={CommonButtonVariants.OUTLINED}
                        label="BUYING"
                        handleClick={() => {
                          setFieldValue('type', 'BUYING');
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <CommonButton
                        className={`w-100 text-sm font-weight-normal interested-button ${
                          values?.type === 'BOTH' ? 'active' : ''
                        }`}
                        variant={CommonButtonVariants.OUTLINED}
                        label="BOTH"
                        handleClick={() => {
                          setFieldValue('type', 'BOTH');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  <FormInputField
                    name="name"
                    label="Name"
                    invalid={!!name}
                    maxLength={120}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  <FormInputField
                    name="email"
                    label="Email"
                    invalid={!!email}
                    maxLength={120}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  <FormInputField
                    name="contactNumber"
                    label="Contact number"
                    invalid={!!contactNumber}
                    maxLength={120}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  <FormInputField
                    name="message"
                    type={FormInputFieldType.TEXTAREA}
                    label="Message"
                    invalid={!!message}
                    maxLength={120}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  <CommonButton type="submit" label="Send Message" className="text-sm w-100" />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

ContactUsForm.defaultProps = {
  light: false,
};

ContactUsForm.propTypes = {
  light: PropTypes.bool,
};

export default ContactUsForm;
