import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';

import { PhotoUploadState } from 'itrade-admin-panel/src/constants/PhotoUploadState';
import { useDropzone } from 'react-dropzone';
import exclamationIcon from '../../assets/img/icons/exclamation.svg';
import arrowsIcon from '../../assets/img/icons/arrows-blue.svg';
import plusIcon from '../../assets/img/plus-icon.png';

import {
  getDraft,
  handleUploadPhotoError,
  removePhotos,
  saveAddPhotoDraft,
  saveToSendVehiclePhoto,
  sendVehiclePhoto,
  setFormStep,
  setPhotoStep,
  sortPhotosAndFillEmpty,
  updatePhotos,
} from '../AddVehicleActions';
import { ADD_DRAFT_VALIDATION_ERRORS, CLEAR_PHOTOS_TO_DELETE } from '../AddVehicleReducer';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import { PhotoPerspective } from '../../constants/PhotoPerspective';
import CommonShadowLoader, {
  CommonShadowLoaderType,
} from '../../common/components/CommonShadowLoader';
import CommonDeleteIconButton from '../../common/components/CommonDeleteIconButton';
import CommonEditIconButton from '../../common/components/CommonEditIconButton';
import { getPhotoDescription } from '../../common/helpers/getPhotoDescription';
import { validateVehiclePhotos } from '../helpers/draftValidator';
import { checkPhotoStep } from '../helpers/checkPhotoStep';
import { resizeImage } from '../../common/utils/image/Image.utils';

const AddPhotoSummary = ({ nextForm, values, form }) => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.addVehicle.get('id'));
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const photos = Array.from(useSelector(state => state.addVehicle.get('photos')) || []);
  const photosToDelete = useSelector(state => state.addVehicle.get('photosToDelete')) || [];
  const sentPhotos = photos.slice();
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));

  const [gridColumnCount, setGridColumnCount] = useState(3);
  const [isFileDragging, setIsFileDragging] = useState(false);
  const [files, setFiles] = useState({});
  const sendingPhotos = useRef(false);
  const inputRef = useRef(null);

  useEffect(() => {
    return () => {
      dispatch({
        type: ADD_DRAFT_VALIDATION_ERRORS,
        payload: {
          formStep: 4,
          errors: validateVehiclePhotos(values),
        },
      });
    };
  }, [dispatch, values]);

  const updateDimensions = useCallback(() => {
    setGridColumnCount(window.innerWidth > 767 ? 3 : 1);
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  useEffect(() => {
    const hasPending = photos.some(p => p.status === PhotoUploadState.PENDING && p.file);
    if (!hasPending) {
      sendingPhotos.current = false;
      return;
    }
    if (sendingPhotos.current) return;
    sendingPhotos.current = true;
    const tasks = photos.map((item, index) => {
      if (item.status === PhotoUploadState.PENDING && item.file) {
        return dispatch(sendVehiclePhoto(item.file, photos, index, null, true))
          .then(() => true)
          .catch(() => false);
      }
      return null;
    });
    Promise.all(tasks).then(results => {
      if (results.some(r => r === true)) {
        dispatch(saveAddPhotoDraft())
          .then(() => dispatch(getDraft(id)))
          .then(() => {
            sendingPhotos.current = false;
          });
      } else {
        sendingPhotos.current = false;
      }
    });
  }, [photos, dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_PHOTOS_TO_DELETE });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!photos.length && photosToDelete.length) {
      nextForm(4);
    }
  }, [photos, photosToDelete, nextForm]);

  function onChange(sourceId, sourceIndex, targetIndex) {
    setIsFileDragging(true);
    const newArr = swap(photos, sourceIndex, targetIndex);
    newArr.forEach((item, i) => {
      item.perspective = getPhotoDescription(i + 1).PERSPECTIVE;
      item.sortNumber = i;
    });
    dispatch(updatePhotos(newArr));
    setTimeout(() => setIsFileDragging(false), 200);
  }

  function saveAndGet() {
    dispatch(saveAddPhotoDraft()).then(() => dispatch(getDraft(id)));
  }

  const handleDelete = index => {
    dispatch(removePhotos(index, sentPhotos, vehicleMedia, photosToDelete));
  };

  const handleEditPhotoClick = (e, item) => {
    saveAndGet();
    dispatch(setPhotoStep(checkPhotoStep(item)));
    dispatch(setPhotoStep(+e.currentTarget.dataset.index + 1));
    dispatch(setFormStep(4));
  };

  const handleAddObligatoryPhoto = e => {
    if (!isFileDragging) {
      const photoIndex = +e.currentTarget.dataset.index;
      dispatch(setPhotoStep(photoIndex));
      inputRef.current.click();
    }
  };

  const handleAddAdditionalPhoto = () => {
    if (canAddMorePhotos()) {
      dispatch(setPhotoStep(photos.length + 1));
      dispatch(setFormStep(4));
    }
  };

  const canAddMorePhotos = useCallback(() => {
    const filled = photos.filter(p => p.fileUrl);
    return filled.length >= REQUIRED_PHOTOS_AMOUNT;
  }, [photos]);

  const getLoaderStatusByPhotoStatus = st => {
    switch (st) {
      case PhotoUploadState.PENDING:
        return CommonShadowLoaderType.PENDING;
      case PhotoUploadState.IN_PROGRESS:
        return CommonShadowLoaderType.IN_PROGRESS;
      default:
        return CommonShadowLoaderType.NOT_PENDING;
    }
  };

  const handleFileDrop = useCallback(
    (e, index) => {
      e.preventDefault();
      e.stopPropagation();
      const file = e.dataTransfer.files?.[0];
      if (!file) return;
      const updated = [...photos];
      updated[index] = {
        ...updated[index],
        fileUrl: URL.createObjectURL(file),
        file,
        status: PhotoUploadState.PENDING,
      };
      dispatch(updatePhotos(updated));
    },
    [dispatch, photos],
  );

  const handlePhotoUpload = useCallback(
    async acceptedFiles => {
      if (!acceptedFiles.length) return dispatch(handleUploadPhotoError(true));
      dispatch(handleUploadPhotoError(false));
      const files = await Promise.all(
        acceptedFiles.map(async (image, imgIndex) => {
          const imageResized = await resizeImage(image);
          Object.assign(imageResized, {
            fileUrl: URL.createObjectURL(imageResized),
            description: getPhotoDescription(currentStep + imgIndex).PERSPECTIVE,
            state: PhotoUploadState.PENDING,
          });
          return imageResized;
        }),
      );
      inputRef.current.value = null;
      return setFiles(files);
    },
    [dispatch],
  );

  useEffect(() => {
    if (files.length === 1) {
      dispatch(sendVehiclePhoto(files[0], sentPhotos, currentStep - 1)).then(() => {
        dispatch(saveAddPhotoDraft(() => dispatch(getDraft(id))));
        dispatch(sortPhotosAndFillEmpty(sentPhotos));
      });
    } else if (files.length > 1) {
      files.forEach((file, fileIndex) => {
        const indexToSave =
          fileIndex === 0
            ? currentStep - 1
            : getFirstEmptyIndex(currentStep - 1 + fileIndex, sentPhotos);
        dispatch(saveToSendVehiclePhoto(file, sentPhotos, indexToSave));
      });
      dispatch(sortPhotosAndFillEmpty(sentPhotos));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, dispatch]);

  const getFirstEmptyIndex = (minIndex, sentPhotos) => {
    if (!sentPhotos[minIndex]?.fileUrl) {
      return minIndex;
    }
    return getFirstEmptyIndex(minIndex + 1, sentPhotos);
  };

  const { getInputProps } = useDropzone({
    onDrop: acceptedFiles => handlePhotoUpload(acceptedFiles),
    noClick: true,
  });

  return (
    <>
      <Row>
        <Col>
          <h5 className="font-weight-bold m-0">Photos</h5>
          <p className="text-sm mt-2">
            {`Minimum of ${REQUIRED_PHOTOS_AMOUNT} images is required. Drag & drop to reorder. You can also drop a file onto an empty tile.`}
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="p-0">
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={gridColumnCount}
              rowHeight={220}
              style={{
                height: `${220 * Math.ceil((photos.length + 1) / gridColumnCount) + 30}px`,
              }}
            >
              {photos.length >= REQUIRED_PHOTOS_AMOUNT &&
                photos.map((item, index) => (
                  <GridItem key={item.id}>
                    {item.fileUrl ? (
                      <div className="photo-summary__photo-wrapper">
                        <CommonShadowLoader status={getLoaderStatusByPhotoStatus(item.status)}>
                          <div className="position-relative w-100 h-100 d-flex justify-content-center align-items-center">
                            <div className="overflow-hidden bg-white h-100 w-100">
                              <img
                                src={item.fileUrl}
                                alt="Vehicle"
                                className="photo-summary__photo no-selectable"
                                draggable="false"
                              />
                            </div>
                            {item.status !== PhotoUploadState.IN_PROGRESS &&
                              item.status !== PhotoUploadState.PENDING && (
                                <>
                                  <div className="photo-summary__drag-position no-selectable">
                                    <img src={arrowsIcon} height={24} width={24} alt="drag" />
                                  </div>
                                  <div className="photo-summary__photo-icon-button-container no-selectable">
                                    <CommonDeleteIconButton
                                      className="photo-summary__photo-icon-button"
                                      dataIndex={index}
                                      handleClick={() => handleDelete(index)}
                                    />
                                    <CommonEditIconButton
                                      className="photo-summary__photo-icon-button"
                                      dataIndex={index}
                                      handleClick={e => handleEditPhotoClick(e, item)}
                                    />
                                  </div>
                                </>
                              )}
                            {item.perspective !== PhotoPerspective.MASTER &&
                              item.damages?.length > 0 && (
                                <div className="photo-summary__photo-damages-icon no-selectable">
                                  <img
                                    width={32}
                                    height={32}
                                    src={exclamationIcon}
                                    alt="Warning icon"
                                  />
                                </div>
                              )}
                          </div>
                        </CommonShadowLoader>
                      </div>
                    ) : (
                      <div className="photo-summary__add-obligatory-photo-wrapper">
                        <button
                          type="button"
                          data-index={index + 1}
                          className="add-photo__base-style photo-summary__add-obligatory-photo-button"
                          onClick={e => {
                            e.stopPropagation();
                            handleAddObligatoryPhoto(e);
                          }}
                          onDragOver={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onDrop={e => handleFileDrop(e, index)}
                        >
                          <p className="add-photo__drop-text m-0">CLICK TO BROWSE OR</p>
                          <p className="add-photo__drop-text m-0">DRAG & DROP PHOTO HERE</p>
                          <div className="add-photo__add_center-icon mt-3">
                            <img src={plusIcon} alt="Add" />
                          </div>
                        </button>
                      </div>
                    )}
                  </GridItem>
                ))}
              <GridItem key="add-photo-tile">
                <div className="photo-summary__photo-wrapper">
                  <button
                    type="button"
                    className="add-photo__base-style photo-summary__add-additional-photo-button"
                    onClick={handleAddAdditionalPhoto}
                    onDragOver={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={e => {
                      if (canAddMorePhotos()) {
                        handleFileDrop(e, photos.length);
                      }
                    }}
                  >
                    <p className="add-photo__drop-text m-0">CLICK TO BROWSE OR</p>
                    <p className="add-photo__drop-text m-0">DRAG & DROP PHOTO HERE</p>
                    <div
                      className={`add-photo__add_center-icon ${
                        canAddMorePhotos() ? '' : 'photo-place-grayed'
                      } mt-3`}
                    >
                      <img src={plusIcon} alt="Add" />
                    </div>
                  </button>
                </div>
              </GridItem>
            </GridDropZone>
          </GridContextProvider>
        </Col>
      </Row>
      <input {...getInputProps()} ref={inputRef} style={{ display: 'none' }} />
    </>
  );
};

export default AddPhotoSummary;
