import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { CommonButtonVariants } from '../constants/CommonButtonVariants';
import CommonButton from '../common/components/CommonButton';

const AddVehicleFooter = ({
  backButtonDisabled,
  nextButtonDisabled,
  backButtonHandler,
  nextButtonHandler,
  nextButtonText,
  hideBackButton,
  launchVehicleHandler,
  launchVehicleButtonDisabled,
  showLaunchVehicleButton,
}) => {
  return (
    <Row className="add-vehicle-header account-layout__footer">
      <Col sm={12} className="p-0">
        <div className="ml-auto d-flex flex-row add-vehicle-footer__buttons-container">
          {!hideBackButton ? (
            <CommonButton
              label="Back"
              disabled={backButtonDisabled || !backButtonHandler}
              variant={CommonButtonVariants.OUTLINED}
              handleClick={backButtonHandler}
            />
          ) : (
            ''
          )}
          <Row>
            <CommonButton
              label={`${nextButtonText || 'Next'}`}
              disabled={nextButtonDisabled || !nextButtonHandler}
              variant={CommonButtonVariants.PRIMARY}
              handleClick={nextButtonHandler}
            />
            {showLaunchVehicleButton && (
              <CommonButton
                label="Launch"
                className="ml-3 mr-3"
                variant={CommonButtonVariants.PRIMARY}
                disabled={launchVehicleButtonDisabled}
                handleClick={launchVehicleHandler}
              />
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

AddVehicleFooter.defaultProps = {
  hideBackButton: false,
  backButtonDisabled: false,
  nextButtonDisabled: false,
  showLaunchVehicleButton: false,
  launchVehicleButtonDisabled: true,
  backButtonHandler: () => {},
  nextButtonHandler: () => {},
  nextButtonText: 'Next',
};

AddVehicleFooter.propTypes = {
  hideBackButton: PropTypes.bool,
  backButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  launchVehicleButtonDisabled: PropTypes.bool,
  showLaunchVehicleButton: PropTypes.bool,
  backButtonHandler: PropTypes.func,
  nextButtonHandler: PropTypes.func,
  nextButtonText: PropTypes.string,
};

export default AddVehicleFooter;
