import React from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import CommonDeleteIconButton from 'common/components/CommonDeleteIconButton';
import { FieldArray } from 'formik';
import DamageSelect from '../helpers/getDamageSelectOptions';
import { removeDamages, setDamageType, setFormStep } from '../AddVehicleActions';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { DamageTypesName } from '../../constants/DamageTypes';
import { checkInteriorPerspective } from '../helpers/checkInteriorPerspective';

const damageTypes = constantToSelect(DamageTypesName);
const AddDamageDragPreviewInner = ({ form, remove }) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const index = currentStep - 1;
  const damages = form.values.vehicleMedia?.photos[index]?.damages;
  const isInteriorPerspective = checkInteriorPerspective(
    form.values.vehicleMedia?.photos[index].perspective,
  );
  const sentPhotos = form?.values?.vehicleMedia?.photos;
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));

  const handleDelete = e => {
    const damageToRemove = e.currentTarget.dataset.step;
    const damageId = sentPhotos[index]?.damages[damageToRemove]?.mediaMetadataId;
    dispatch(removeDamages(index, sentPhotos, damageId, damageToRemove, vehicleMedia));
    remove(damageToRemove);
  };

  const updateDamageType = (option, damageIndex) => {
    dispatch(setDamageType(option, damageIndex, index));
  };
  return (
    <>
      {damages.map((e, index) => (
        <Col md={4} sm={12}>
          <div key={Math.random()} className="damages__container d-flex flex-column">
            <div className="damages__preview mb-3">
              <div
                className="damages__image mb-1"
                style={{ backgroundImage: `url(${e.fileUrl})` }}
              />
              <Row>
                <Col className="d-flex flex-row align-items-center">
                  <span className="damages__title text-sm">DAMAGE {index + 1}</span>
                  <CommonDeleteIconButton
                    dataStep={index}
                    className="damages__delete-button ml-auto"
                    handleClick={handleDelete}
                  />
                </Col>
              </Row>
            </div>
            <div className="damages__select-area">
              <Col className="d-flex flex-column p-0">
                {!isInteriorPerspective && (
                  <DamageSelect
                    options={damageTypes}
                    name={`vehicleMedia.photos[${currentStep -
                      1}].damages[${index}].damageType`}
                    label="Select Type of Damage"
                    currentStep={currentStep - 1}
                    index={index}
                    onChangeHandler={updateDamageType}
                  />
                )}
              </Col>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

const AddDamageDragPreview = props => {
  const currentStep = useSelector(state => state.addVehicle.get('step'));
  const index = currentStep - 1;
  return (
    <FieldArray
      {...props}
      name={`vehicleMedia.photos[${index}].damages`}
      component={AddDamageDragPreviewInner}
    />
  );
};

export default AddDamageDragPreview;
